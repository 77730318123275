.row {
    display: flex;
    align-items: center;
}

.rowLabel{
    width: 30%;
    em {
        color: red;
    }
}

.rowValue{
    width: 70%;
}