.container {
    padding: 10px;
}

.header {
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.body {
    background-color: #fff;
    height: 100%;
}

.loader{
    position: fixed;
    top: 0;
    z-index: 1100;
    height: 100vh;
    width: 100vw;
}
