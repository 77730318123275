.infoline {
    display: flex;
    padding: 0 10px;
}

.infoblock {
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-right: 20px;
    }
}

.gatePreview {
    margin-right: 10px;
}
