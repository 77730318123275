.function {
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

.bold {
    font-weight: bold;
}

.commentActions {
    width: 100%;
}

.commentAction {
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.documentIconWrapper {
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.documentIcon {
    cursor: pointer;
    color: #888;
    font-size: 20px;
    position: relative;
}


.projectManagerRating,
.assessorRating,
.projectManagerCommentCell,
.gateAssessorCommentCell,
.confirmButton {
    position: relative;
}

.action {
    margin-bottom: 10px;
    &:not(:last-child) {
        margin-right: 5px;
    }
}


.approveDenyBlock{
    display: block !important;
}

.approveDenyButton{
    margin-top: 5px;
}
.statusButton {
    position: relative;
    margin-top: 5px;
    text-align: center;
}
.approversButton {
    font-size: 20px;
    position: relative;
    margin-left: 5px;
}
