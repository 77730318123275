.actionItemContainer {
    input,
    textarea {
        display: block;
        width: 100%;
    }

}

.row, 
.textAreaWrapper {
    margin: 10px 0;
}

.row {
    display: flex;
    align-items: center;
}

.rowLabel {
    width: 30%;
    margin-right: 10px;
    em {
        color: red;
    }
}

.rowPicker {
    width: 70%;
    margin-left: 5px;
}

.list {
    margin-bottom: 10px;
}

.documents {
    margin-top: 10px;
}

.documentRow {
    margin-right: 0px !important;
    margin-left: 0px !important;
    border-bottom: 1px solid #999;
    margin-bottom: 5px !important;
}
