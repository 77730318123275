.container {
    width: 100%;
    font-size: 14px;
}

.head {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    background-color: #d9d9d9;
}

.headItem {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
}

.headItemText {
    line-height: 11px;
    margin-left: 2px;
}

.burger {
    margin-left: auto;
    cursor: pointer;
}

.closed {
    position: relative;
    padding-right: 15px;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 5px solid #777;
        transition: transform linear 0.1s;
    }
}

.opened {
    composes: closed;
    &:after {
        transform: translateY(-50%) rotate(180deg);
    }
}

.body {
    width: 100%;
    padding: 5px;
}

.line {
    width: 100%;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(350px, 1fr) );
    gap: 10px 15px;
}

.item {
    width: 100%;
}

.title {
    font-family: 'Droid Sans';
    text-transform: uppercase;
    margin-bottom: 5px;
}

.switch {
    display: inline-flex;
    align-items: center;
    margin-top: 25px;
}

.toggler {
    margin-right: 5px;
}
