.block {
    display: flex;
    align-items: center;
}

.blockTitle {
    position: relative;
    flex-grow: 1;
}

.blockTitleText {
    position: relative;
    background-color: #fff;
    display: inline-block;
    padding: 0 5px;
    margin-left: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Droid Sans';
    margin-bottom: 5px;
    z-index: 1;
}

.blockTitleLine {
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #555;
    bottom: 24px;
    z-index: 0;
}

.blockToggler {
    padding: 0 10px;
}
.status{
    position: absolute;
    right: 0;
    bottom: 15px;
    background: #fff;
    padding-left: 10px;
}
