.description {
    margin-bottom: 10px;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
