.dx-datagrid {
    cursor: inherit;
}

.dx-datagrid-focus-overlay {
    border:  none;
}

.rdw-dropdown-optionwrapper {
    width: 100% !important;
}

.rdw-link-modal-target-option {
    display: none;
}