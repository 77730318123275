.content {
    padding-top: 20px;
}
.table {
    margin-bottom: 30px;
}

.wrapper {
    position: relative;
}
