.timeline {
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .timeBlock {
    min-height: 100%;
    box-sizing: border-box;
    border-left: 1px dashed rgba(55, 55, 58, 0.4);
    color: #777;
    position: absolute;
    top: 0;
    bottom: 0;
    padding-left: 10px;
    padding-top: 10px;
    pointer-events: none;
    white-space: nowrap;
  }

  .events {
    padding-top: 55px;
  }

  .event {
    padding-bottom: 10px;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;

    b {
      padding-right: .5em;
    }
  }

  .selectedEvent {
    font-weight: 600;

    .time {
      box-shadow: 0px 0px 4px 0px #1CFF00;
    }
  }

  .time {
    display: inline-block;
    height: 8px;
    background: #444;
    border-radius: 2px;
    margin-right: 10px;
    opacity: .5;
    position: relative;
    min-width: 2px;

    &:hover {
        opacity: .8;
    }

    &-bug {
      position: absolute;
      top: -5px;
      left: -20px;
      color: red;
    }
  }

  .eventDetails {
    padding-top: 20px;    
  }

  .sqlhlkeyword {
    font-weight: bold;
    color: blue;
  }

  .sqlhlstring {
    color: red;
  }

  .requestDecodeError{ 
    color: red;
    font-weight: bold;
  }