.table {
    margin-bottom: 30px;
}

.functionFilterWrapper {
    margin-bottom: 10px;
    display: flex;
}

.tableTitle {
    font-family: 'Droid Sans';
    margin-bottom: 10px;
}

.wrapper {
    position: relative;
}

.edit {
    position: absolute;
    right: 5px;
    top: -50px;
    z-index: 2;
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;
}