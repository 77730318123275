.link {
    color: #0076b7;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
.dismissButton{
    color: #959595;
    font-size: 12px;
    cursor: pointer;
}
