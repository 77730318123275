.personList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // max-height: 407px;

    & > div {
        margin-bottom: 10px;
    }
}

.personListRow {
    display: flex;
    align-items: center;

    & svg {
        visibility: hidden;
        cursor: pointer;
    }
    &:hover {
        & svg {
            color: gray;
            visibility: visible;
            &:hover {
                color: black;
            }
        }
        
    }
}

.personItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mandatory {
    flex-basis: 80px;
    flex-shrink: 0;
}