.function {
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

.bold {
    font-weight: bold;
}

.projectManagerCommentCell,
.gateAssessorCell,
.gateAssessorRatingCell,
.projectManagerRatingCell,
.confirmButton {
    position: relative;
}

.questionTitle {
    font-family: 'Droid Sans';
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}