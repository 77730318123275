.accordionTab {
    margin-bottom: 2px;
}

.accordionTabTitle {
    line-height: 40px;
    cursor: pointer;
    user-select: none;
    padding-left: 10px;

    &:hover {
        background-color:  rgb(235, 236, 240);
    }
}
.accordionTabBody {
    padding-left: 38px;
    padding: 10px 10px 20px 38px;
}

.accordionTabOpen {
    background-color:  rgb(235, 236, 240);
}