.loaderWraper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 4000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loaderContainer {
    position: relative;
}

.panel {
    padding: 20px 80px;
    background: #ffffffee;
    border-radius: 10px;
    box-shadow: 0px 0px 16px -6px #2e3134;
}
