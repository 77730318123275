.gateAssessorCommentCell,
.assessorRatingCell,
.confirmButton {
    position: relative;
}
.guideline{
    white-space: pre-line;
}

.questionTitle {
    font-family: 'Droid Sans';
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}