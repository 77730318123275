.tooltip{
  background: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
  padding: 8px !important;

  & .arrow:before {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
  }
  & > *{
    opacity: 1!important;
  }
}

