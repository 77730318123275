.wrapper {
    display: flex;
    flex-direction: column-reverse;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.editor {
    padding: 5px !important;
    border-radius: 2px !important;    
    cursor: text;
}

.toolbar {
    margin: 0 !important;
    border: none !important;
    border-top: 1px solid #ced4da !important;
}

.linkPopup {
    position: initial !important;
}
