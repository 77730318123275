.loader {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table {
    margin-bottom: 10px;
}

.count {
    display: inline-flex;
    background-color: #eee;
    padding: 5px;
    border-radius: 2px;
}

.link {
    color: #0076b7;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.overdue {
    color: red;
}
