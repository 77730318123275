.commentsSummary {
    margin-bottom: 10px;
}

.red {
    color: red;
}
.tooltip{
    display: flex;
    flex-direction: column;
}

.redText {
    position: relative;
    z-index: 1;
}

.pointerCursor {
    cursor: pointer;
}