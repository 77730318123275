.colorsDefinition {
    margin: 15px 0 10px;
}

.colorLabel {
    margin: 0;
}

.colorPickerRow {
    align-items: center;
    justify-content: space-between;
    max-width: 140px;
    margin: 10px 0 !important;
}

.disabledRow {
    position: relative;
    z-index: 100;
    opacity: .65;
    pointer-events: none;
}
.radio{
    display: flex;
    justify-content: space-between;
}
.info{
    display: flex;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    margin-bottom: 10px;
   .icon {
        padding: 5px;
       & *{
           fill: red;
       }
    }
    & .content{
        white-space: pre-line;
        padding: 5px;
        & :first-child{
            margin-right: 5px;
        }
    }
}
