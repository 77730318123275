.wrap{
    position: relative;
    display: flex;
    justify-content: space-around;
}
.ratingPicker {
    width: 50px;
    margin: 0 auto;
}
.validationHint{
    text-align: center;
}
