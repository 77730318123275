.line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.picker {
    display: flex;
    align-items: center;
    &:first-child {
        margin-right: 20px;
    }


    label {
        margin-bottom: 0 !important;
        margin-right: 10px;
    }
}