.actions {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}

.action {
    margin-right: 5px;
    flex-basis: 31px;
    flex-shrink: 0;
    flex-grow: 0;
}

.approvalCommentCommentCell {
    position: relative;
}

.validationHint{
    margin-right: 5px;
    width: 30px;
}

.isExternalManagementText {
    color: red;
    font-style: italic;
}