.line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.field {
    width: 100%;

    &:not(:last-child) {
        margin-right: 5px;
    }
}

.halfField {
    width: calc(50% - 5px);

    &:first-child {
        margin-right: 5px;
    }
}