.line {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.item {
    flex: 1 0 auto;
    max-width: calc(33.3% - 10px);
    width: calc(33.3% - 10px);
    margin-bottom: 5px;
    margin-right: 10px;
    &:nth-child(3n + 3) {
        max-width: 33.3%;
        width: 33.3%;
        margin-right: 0;
    }
}

.title {
    font-family: 'Droid Sans';
    text-transform: uppercase;
    margin-bottom: 5px;
}

.data {
    padding: 0 5px;
    height: 23px;
    line-height: 23px;
    text-align: middle;
    background-color: #d1d1d1;
    border-radius: 3px;
}

.currencyBlock {
    display: flex;
}
.currencyValue {
    padding: 2px 5px;
    background-color: #d1d1d1;
    border-radius: 3px;
    flex: 1;
}
.currencyPostfix {
    background-color: white;
    float: right;
    padding: 2px 5px;
}

.buttons {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.button {
    &:not(:last-child) {
        margin-right: 10px;
    }
}

.star {
    color: red;
}

.link {
    color: #0076b7;
}
