.container {
    display: flex;
    align-items: center;
}

.block {
    &:not(:last-child) {
        margin-right: 10px;
    }
}
