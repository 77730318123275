.customOption {
    height: 100%;
    width: 60px;
    border-radius: 3px;
}

.colorLetter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    height: 100%;
}