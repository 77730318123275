.confirmButton {
    background-color: #ffffff;
    border: 1px solid #b6b6b65c;
    cursor: pointer;
    outline: none;
    padding: 0.2em;
    border-radius: 2px;
    &:active{
        outline: 5px auto -webkit-focus-ring-color;
    }
    &:focus:not(:active){
        outline: none;
    }
}

.disabledConfirmButton
{
    background-color: #ffffff;
    border: 1px solid #b6b6b65c;
    outline: none;
    padding: 0.2em;
    border-radius: 2px;
    opacity: .65;
    pointer-events: none;
}
