.gateAssessorCommentCell,
.assessorRatingCell,
.projectManagerRatingCell,
.confirmButton {
    position: relative;
}

.comment {
    white-space: pre-line;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

.commentActions {
    width: 100%;
}

.commentAction {
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.documentIconWrapper {
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.documentIcon {
    cursor: pointer;
    color: #888;
    font-size: 20px;
    position: relative;
}
