.commentColumn,
.ratingColumn,
.questionColumn,
.projectManagerRatingCell {
  position: relative;
}

.questionTitle {
  font-family: 'Droid Sans';
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
}

.ratingCellWrapper {
  display: flex;
  flex-direction: column;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
}

.ratingWrapper {
  flex-grow: 1;
  position: relative;
}

.commentActions {
  width: 100%;
}

.commentAction {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.documentIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.documentIconButton{
  margin-top: 5px;
}
