.loaderWrapper {
    display: flex;
    justify-content: center;
    padding: 30px;
}

.table {
    margin-bottom: 10px;
}

.actions {
    display: flex;
    align-items: center;
}

.action {
    &:not(:last-child) {
        margin-right: 5px;
    }
}

.wrapper {
    position: relative;
}


