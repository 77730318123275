.button{
  margin-left: 5px;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}

.disableButton
{
  margin-left: 5px;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 50%;
  opacity: .65;
  pointer-events: none;
}
