.container {
    width: 100%;
}

.item {
    margin-top: 20px;
}

.buttonRemoveProjects{
    margin-top: 60px;
}

.buttonRemoveTemplates{
    margin-top: 60px;
    margin-left: 20px;
}