.actions {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.action {
    &:not(:last-child) {
        margin-right: 5px;
    }
}

.approvalCommentCommentCell {
    position: relative;
}
