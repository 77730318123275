.loaderWrapper {
    display: flex;
    justify-content: center;
    padding: 30px;
}

.table {
    margin-bottom: 10px;
}

.link {
    cursor: pointer;
    color: #00f;
    &:hover {
        text-decoration: underline;
    }
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.actionsLabel {
    margin-top: 5px;
}

.action {
    margin-right: 5px;
    margin-bottom: 2px;
}

.wrapper {
    position: relative;
}

.edit {
    position: absolute;
    right: 5px;
    top: -50px;
    z-index: 2;
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;
}

.buttons {
    display: flex;
}

.trashBtn {
    flex-basis: 40px;
    flex-shrink: 0;
}

.gateActionBtn {
    margin-left: 2px;
    margin-right: 2px;
    flex-basis: 95px;
    flex-shrink: 0;
}

.gateWideActionBtn {
    margin-left: 2px;
    margin-right: 2px;
    flex-basis: 150px;
    flex-shrink: 0;
}
