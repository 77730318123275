.copyButton {
    margin-right: 5px;
    height: 32px;
}

.dropdownButton {
    width: 32px !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 16px !important;
    border-radius: 2px !important;
}

.dropdownItem {
    padding: 8px 16px !important;
}
