.container {
    display: flex;
}

.row {
  border-bottom: 1px solid #ddd !important;
  td {
    border-right: 1px solid #ddd !important;
    text-align: left !important;
  }
}

