.meetingContainer {
    input,
    textarea {
        display: block;
        width: 100%;
    }
}

.dateRow {
    display: flex;
    margin: 10px 0;
}

.datePicker {
    input {
        line-height: 1.5;
    }
}

.titleWrapper {
    margin: 30px 0;
}

.textArea {
    &:focus {
        border: 2px solid #4180d1 !important;
        box-shadow: none !important;
    }
}

.btns {
    display: flex;
}

.btn {
    &:not(:last-child) {
        margin-right: 5px;
    }
}


.label {
    font-size: .9em;
    color:gray;
    margin-bottom: 2px;
}