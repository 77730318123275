.functions {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.function {
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

.projectManagerCommentCell,
.gateAssessorCell,
.gateAssessorRatingCell,
.projectManagerRatingCell,
.confirmButton {
    position: relative;
}

.commentsSummary {
    margin-bottom: 0px;
}

.questionTitle {
    font-family: 'Droid Sans';
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}