.loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 5px;
    top: 5px;
    left: 5px;
    right: 5px;
}

.overlay {
    position: absolute;
    bottom: 5px;
    top: 5px;
    left: 5px;
    right: 5px;
}

.editModeWrapper {
    height: 100%;
}

.editModeWrapperPos {
    display: flex;
    max-width: 500px;
}

.confirmationCellWrapper {
    a {
        position: relative;
        z-index: 1;
    }
}

.editModeWrapper .contentWrapepr{
    margin-bottom: 5px;
}

.editModeWrapperPos .contentWrapepr{
    flex-grow: 1;
    margin-right: 10px;
}

.buttonsOverlay {
    position: absolute;
    right: 0;
}
