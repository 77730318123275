.dragWrapper {
    position: relative;
}

.dragContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px dashed #959595;
    background-color: rgba(255, 255, 255, .8);
}

.dragText {
    color: #959595;
    font-size: 20px;
}

.dragIcon {
    color: #959595;
}
