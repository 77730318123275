.questionTitle {
    font-family: 'Droid Sans';
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}

.comment {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.commentText {
    width: 100%;
    height: 100%;
}

.ratingColumn {
    position: relative;
}

.editField {
    padding: 5px;
}

.ratingPicker {
    width: 50px;
    margin-bottom: 20px;
}

.commentActions {
    width: 100%;
}

.commentAction {
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.flags {
    width: 100%;
    margin-bottom: 20px;
}

.flag {
    margin-bottom: 10px;
    display: flex;
}

.checkbox {
    margin-right: 5px;
}

.label {
    text-align: left;
}

.starWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.projectManagerRatingCell {
    position: relative;
}
