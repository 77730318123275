$color: #999999;

.area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    color: $color;
    border: 3px dashed $color;
    opacity: .9;
    transition: .1s;
    cursor: pointer;

    &:hover,
    &.dragging {
        background-color: rgba($color: $color, $alpha: 0.1);
    }
}

.text {
    color: inherit;
    font-size: 20px;
}

.icon {
    color: inherit;
}
