.actions {
    display: flex;
    align-items: center;
}

.action {
    &:not(:last-child) {
        margin-right: 5px;
    }
}

.checkbox {
    margin-left: 20px;
}

.categoryNameCell {
    position: relative;
}