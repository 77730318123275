.addButton {
  font-size: 1.1em;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  cursor: pointer;
  user-select: none;
  
  & > span {
    margin-left: 10px;
  }
}