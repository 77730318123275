.container {
    width: 100%;
    color: #555;
}

.description {
    margin-bottom: 20px;
}

.open {
    position: relative;
    padding-right: 20px;
    cursor: pointer;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 7px solid #555;
        transition: transform linear 0.1s;
    }
}

.close {
    composes: open;
    &:after {
        transform: translateY(-50%) rotate(180deg);
    }
}

.blockTitle {
    position: relative;
    flex-grow: 1;
}

.blockTitleText {
    position: relative;
    background-color: #fff;
    display: inline-block;
    padding: 0 5px;
    margin-left: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Droid Sans';
    margin-bottom: 5px;
    z-index: 1;
}

.blockTitleLine {
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #555;
    bottom: 15px;
    z-index: 0;
}

.statusColumn {
    position: relative;
    padding: 0 !important; 
}

.status {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}