.gates {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.gate {
    padding: 0 10px;
    color: #000;
    &:hover {
        color: #000;
    }
}

.link {
    color: #000;
    min-width: 60px;
    &:hover {
        color: #000;
    }
}

.gateInfoWrapper {
    font-size: 14px;
    text-align: left;
}

.tooltipTitle {
    text-align: center;
    font-weight: bold;
}
