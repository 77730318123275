.table {
    margin-bottom: 30px;
}

.tableTitle {
    font-family: 'Droid Sans';
    margin-bottom: 10px;
}

.wrapper {
    position: relative;
}

.decisionWrapper {
    display: flex;
    align-items: center;
}

.decisionButtn {
    margin-bottom: 10px;
    margin-left: 15px;
}

.functionFilterWrapper {
    margin-bottom: 10px;
}

.navTab {
    background-color: #fff;
    display: inline-block;
    padding: 7px 12px;
    color: #0076b7;
    border-top: 2px solid transparent;
    border-bottom: 1px solid #ddd;
    text-decoration: none;
    margin-bottom: -1px;
    &:hover {
        background-color: oldlace;
    }
}

.active {
    background-color: #fff;
    color: #555;
    font-weight: bold;
    border: 1px solid #ddd;
    border-top: 2px solid #0076b7;
    border-bottom: 1px solid #fff;
    cursor: default;
}