.line {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.item {
    flex: 1 0 auto;
    max-width: calc(33.3% - 10px);
    width: calc(33.3% - 10px);
    margin-bottom: 5px;
    margin-right: 5px;
    &:nth-child(3) {
        max-width: 33.3%;
        width: 33.3%;
        margin-right: 0;
    }
}

.title {
    font-family: 'Droid Sans';
    text-transform: uppercase;
    margin-bottom: 5px;
}

.data {
    padding: 2px 5px;
    background-color: #d1d1d1;
    border-radius: 3px;
}

.buttons {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.button {
    &:not(:last-child) {
        margin-right: 5px;
    }
}
