.documentIcon {
  cursor: pointer;
  font-size: 20px;
  position: relative;
}
.documentIconUploaded{
  color: #FFF !important;
}
.documentIconNotUploaded{
  color: #888 !important;
}

.tinyButton {
  padding: 1px 5px !important;
  padding-bottom: 2px !important;
  margin: 5px;
}

.block{
  position: relative;
}