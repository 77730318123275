.actions {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}

.action {
    &:not(:last-child) {
        margin-right: 5px;
    }
}

.buttons {
    display: flex;
    align-items: center;
}

.button {
    &:not(:last-child) {
        margin-right: 5px;
    }
}
