.line {
    display: flex;
    min-height: 50px;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
}

.description {
    flex-grow: 1;
    display: flex;
}

.actionButtons {
    display: flex;
    flex-basis: 315px;
    flex-shrink: 1;
    align-items: flex-end;
    justify-content: flex-end;
    @media (max-width: 1200px) {
        flex-basis: auto;
    }
    .confirmButton {
        width: 40px;
        height: 32px;
        position: relative;
        z-index: 2;
        margin-right: 10px;
        &>*{
            border-radius: 2px;
            box-shadow: none;
            padding: 5px 10px;
            line-height: 20px;
        }
    }
}

.itemExtraSmall {
    flex: 0 1 140px;
    margin-right: 10px;
}

.itemSmall {
    flex: 0 1 280px;
    margin-right: 10px;
}

.itemLarge {
    flex: 1 1 280px;
    margin-right: 10px;
}

.title {
    font-family: 'Droid Sans';
    text-transform: uppercase;
    margin-bottom: 5px;
}

.data {
    padding: 2px 5px;
    background-color: #d1d1d1;
    border-radius: 3px;
}

.action {
    &:first-of-type{
        height: 32px;
    }
    &:not(:last-child) {
        margin-right: 5px;
    }
    &:last-child{
        width: 145px;
    }
}

.loader{
    //position: relative;;
    align-items: flex-start;
    justify-content: center;
    top: 50px;
}
