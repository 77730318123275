.relatedEditWrapper {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.relatedEditItemWrapper {
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

.relatedEditItemTitle {
    margin-bottom: 5px;
}


.relatedEditItem {
    border: 1px solid #ddd;
}


.valueWrapper {
    display: flex;
    flex-direction: column;
}