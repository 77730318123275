.requestsClear {
    cursor:pointer;
    margin-left:10px;
}

.requestsFilter {
    float: right;
  
    input {
      margin: 0;
      padding: 0 .25rem;
      width: 200px;
      height: 1.75rem;
    }
  }

  .requestList {
    height: 300px;
    overflow-x: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 1px solid #ccc;  
  }

  .requestTime {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 3px;
  }

  .requestItem {
    white-space: pre;
    position: relative;
    padding: 0 5px;
    cursor: default;

    &:hover {
      background-color: #D0D0D0
    }
  }

  .selectedrRequestItem {
    font-weight: bold;
    background-color: #F0F0F0;
  }

  .requestItemWithException {
    color: red;
  }  