.container {
    overflow: hidden;
    border: 1px solid #ddd;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}

.header {
    padding: 7px;
}

.body {
    padding: 8px;
    color: #000;
    overflow-y: auto;
    overflow-x: hidden;
}
