.widgets {
  display: flex;
  flex-wrap: wrap;
}

.widgetWrapper {
  flex: 1 0 auto;
  padding: 5px;
  width: 100%;
  height: 100%;
}

.widget {
  font-size: 14px;
  color: #fff;
  background-color: #fff;
}