.link {
    color: #0076b7;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.filters {
    max-width: 80%;
    margin-bottom: 10px;
}
