.description {
    margin-bottom: 20px;
}

.table {
    margin-bottom: 30px;
}

.tableTitle {
    font-family: 'Droid Sans';
    margin-bottom: 10px;
}

.edit {
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;
}

.saveButton {
    margin-right: 5px;
}

.buttonsWrapper {
    align-self: flex-end;
}

.buttonsWithTimer {
    display: flex;
    flex-direction: column;
}
.loader{
    align-items: flex-start;
    justify-content: center;
    top: 200px;
}
