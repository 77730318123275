.skipped {
    background-color: lightgray !important;
}

.navTab {
    background-color: #fff;
    display: inline-block;
    padding: 7px 12px;
    color: #0076b7;
    border-top: 2px solid transparent;
    border-bottom: 1px solid #ddd;
    text-decoration: none;
    margin-bottom: -1px;
    &:hover {
        background-color: oldlace;
    }
}

.active {
    background-color: #fff;
    color: #555;
    font-weight: bold;
    border: 1px solid #ddd;
    border-top: 2px solid #0076b7;
    border-bottom: 1px solid #fff;
    cursor: default;
}

.childrenWrapper {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}
.icon{
    fill: #ff000f;
}
