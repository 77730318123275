.headerWrapper{
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
}

.checkboxWrapper{
    display: flex;
    align-items: center;
}

.checkbox{
    padding-right: 10px;
}
.checkboxText{
    padding-bottom: 4px;
}