.container {
  input,
  textarea {
    display: block;
    width: 100%;
  }
}

.dateRow {
  display: flex;
  margin: 10px 0;
}

.datePicker {
  input {
    line-height: 1.5;
  }
}
.textArea {
  &:focus {
    border: 2px solid #4180d1 !important;
    box-shadow: none !important;
  }
  width: 100%;
}
.firstPartOfRow{
  width: 50%;
  margin-right: 10px;
}
.secondPartOfRow{
   width: 50%;
 }
.thirdOfRow {
    width: calc(35% - 5px);

    &:first-child {
        margin-right: 5px;
    }

    &:last-child {
        margin-left: 5px;
    }
}
.wholeRow{
  width: 100%;
  max-width: none !important;
}
.label {
  font-size: .9em;
  color:gray;
  margin-bottom: 2px;

  em {
    color: red;
  }
}