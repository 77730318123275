.list {
    margin-bottom: 10px;
}

.buttonLine {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    button {
        margin-right: 5px;
    }
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #999;
    padding-bottom: 5px;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}
.errorContainer {
    font-size: 14px;
    margin: 10px 0;
}
.errorItem {
    color: red;
    margin-bottom: 10px;
}

.checkboxWrapper {
    cursor: pointer;
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
}

.checkbox {
    margin-right: 5px;
}

.checkboxText {
    font-size: 14px;
}

.progress {
    margin: 10px 0;
}

.footerBtnWrapper {
    button {
        margin-left: 10px;
    }
}

.footerRow {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .criticalWarning {
        color: red;
    }
}

.dropdownButton {
    background-color: #00b050 !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
    border-radius: 2px !important;
    &:hover {
        background-color: #98db38 !important;
    }
}
