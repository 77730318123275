.headline {
    margin-bottom: 20px;
}

.widgets {
    display: flex;
    flex-wrap: wrap;
}

.widgetWrapper {
    flex: 1 0 auto;
    padding: 5px;
    width: 100%;
    height: 100%;
}

.widget {
    font-size: 14px;
    color: #fff;
    background-color: #fff;
}

.body {
    color: #000;
}

.title {
    display: flex;
    justify-content: space-between;
}

.titleRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.excelButton {
    padding: 0 3px !important;
    margin-right: 10px !important;
    display: flex !important;
    align-items: center;

    svg {
        margin-right: 5px;
    }
}