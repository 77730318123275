.input {
    width: 100%;
    border: 1px solid #f1f1f1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
    &:focus {
        outline: none;
        padding: 5px !important;
        border-radius: 2px !important;
        background-color: #ffffff !important;
    }
}