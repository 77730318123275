.container {
    display: flex;
}

.button {
    margin-left: auto;
}

.line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.halfField {
    width: calc(50% - 5px);
    &:first-child {
        margin-right: 5px;
    }
}

.thirdField {
    width: calc(35% - 5px);
    &:first-child {
        margin-right: 5px;
    }
    &:last-child {
        margin-left: 5px;
    }
}

.field {
    width: 100%;
    &:not(:last-child) {
        margin-right: 5px;
    }
}

.datePicker {
    max-width: 100% !important;
}

.rowLabel {
    margin-right: 10px;

    em {
        color: red;
    }
}
