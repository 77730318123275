.createActionItemButton {
    margin-bottom: 10px;
}

.remarksCell,
.assessorRatingCell,
.functionRatingCell,
.deadlineCell,
.relatedIdCell,
.responibleTextCell,
.currentStatusCell {
    position: relative;
}

.actions {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}

.action {
    margin-bottom: 10px;
    &:not(:last-child) {
        margin-right: 5px;
    }
}
