.container {
    width: 100%;
}

.headline {
    margin-bottom: 20px;
}

.deliverablesTable th,
td {
    border: 1px solid black;
    font-size: 1rem;
    text-align: center;
    padding: 3px;
}

.verticalTabHeader span {
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-lr;
}

.deliverablesTable th:empty {
    visibility: hidden;
    border: none;
}

.deliverablesTable tr:nth-child(even) {
    background-color: #b8cce4;
}

.deliverablesTable tr:nth-child(odd) {
    background-color: #fcd5b4;
}

.deliverablesTable div tr {
    background-color: unset;
}

.deliverablesTable {
    margin: 0 auto;
}

.tableFixHeadWrp {
    overflow-y: auto;
    height: 500px;
}

.exitStatusColumn {
    font-weight: bold;
}

.gateMeetingColumn {
    background-color: #ff000063 !important;
}

.gatePreparationColumn {
    background-color: #ffff0045 !important;
}

.gridColumn {
    position: relative !important;
}