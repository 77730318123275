.questionTitle {
    font-family: 'Droid Sans';
    font-size: 14px;
    margin-bottom: 10px;
}

.commentText {
    width: 100%;
    height: 100%;
}

.editField {
    padding: 5px;
}

.ratingPicker {
    width: 50px;
    margin: 0 auto 5px auto;
}

.commentActions {
    width: 100%;
}

.commentAction {
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.flags {
    width: 100%;
    margin-bottom: 5px;
}

.flag {
    margin-bottom: 5px;
    display: flex;
}

.checkbox {
    margin-right: 5px;
}

.label {
    text-align: left;
}

.documentsColumn {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    white-space: pre-line;
}

.document {
    overflow-wrap: break-word;
    margin-bottom: 10px;
}

.documentIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.documentIconButton{
    margin-top: 5px;
}

.ratingStatusWrapper {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    text-align: center;
}

.documentStatus {
    margin-right: 20px;
    opacity: 0.75;
}

.statusButton {
    margin-top: 5px;
}

.documentIcon {
    cursor: pointer;
    color: #888;
    font-size: 20px;
    position: relative;
    margin-top: 5px;
}

.approversButton {
    font-size: 20px;
    position: relative;
    margin-top: 5px;
    margin-left: 5px;
}

.documentButton {
    width: 100%;
    margin-bottom: 10px;
}

.starWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.error {
    margin-bottom: 10px;
    color: red;
}

.commentColumn,
.ratingColumn,
.projectManagerRatingCell {
    position: relative;
}

.ratingPickerWrapper {
    position: absolute;
    top: 5px;
    left: 5px;
    bottom: 5px;
    right: 5px;
}

.ratingCellWrapper {
    display: flex;
    flex-direction: column;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
}

.ratingWrapper {
    flex-grow: 1;
    position: relative;
}

.approveDenyBlock{
    display: block !important;
}

.approveDenyButton{
    margin-top: 5px;
}

.isExternalManagementText {
    color: red;
    font-style: italic;
}
