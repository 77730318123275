.actions {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}

.action {
    &:not(:last-child) {
        margin-right: 10px;
    }
}
