.item{
  display: flex;
}
.date {
  font-weight: bold;
  min-width: 80px;
}

.name{
  font-weight: bold;
  min-width: 180px;
}

.comment{
  word-break: break-all;
}