.item{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.name {
    margin: 0 10px;
}

.closeButton{
    margin-left: 30px;
    cursor: pointer;
}
