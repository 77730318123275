.loaderWrapper {
    display: flex;
    justify-content: center;
    padding: 30px;
}

.table {
    margin-bottom: 10px;
}

.wrapper {
    position: relative;
}

.edit {
    position: absolute;
    right: 5px;
    top: -50px;
    z-index: 2;
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;
}


.editCell{
    position: relative;
}