.avatar {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #f8f8f8;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  
}
.letter {
  vertical-align: middle;
  line-height: 30px;
  text-align: center;
  color: white;
}