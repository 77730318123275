.action {
    margin-right: 5px;
    margin-top: 2px;
}

.rowLabel {
    margin-right: 10px;

    em {
        color: red;
    }
}