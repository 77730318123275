.functions {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.comment {
    white-space: pre-line;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

.gateAssessorCommentCell,
.assessorRatingCell,
.projectManagerRatingCell,
.confirmButton {
    position: relative;
}

.questionTitle {
    font-family: 'Droid Sans';
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}