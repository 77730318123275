.container{
  display: flex;
  align-items: center;
  margin-left: auto;
}

.title{
  margin-right: 10px;
}

.selectContainer{
  display: flex;
}

.rolePicker{
  width: 200px;
  margin-right: 5px;
}

.ratingPicker{
  width: 50px;
}