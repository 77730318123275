.footer {
    display: flex;
    justify-content: flex-end;
}

.button {
    &:not(:last-child) {
        margin-right: 10px;
    }
}
