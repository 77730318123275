.loaderWrapper {
    display: flex;
    justify-content: center;
    padding: 30px;
}

.link {
    cursor: pointer;
    color: #00f;
    &:hover {
        text-decoration: underline;
    }
}

.enabledInput {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.actions {
    display: flex;
    align-items: center;
}

.button {
    margin-right: 10px;
}
