.wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.image {
    width: 100%;
    height: 100%;
}
