.content {
    padding-top: 20px;
}
.table {
    margin-bottom: 30px;
}

.tableTitle {
    font-family: 'Droid Sans';
    margin-bottom: 10px;
}

.wrapper {
    position: relative;
}

.button {
    display: flex;
    padding-left: 10px;
}

.buttonWrapper {
    margin-left: auto;
}

.tableHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deletedItemsFilter {
    font-family: 'Droid Sans';
    margin-top: -20px;
    margin-bottom: 10px;
    display: flex;
}