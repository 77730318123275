.description {
    margin-bottom: 20px;
}

.table {
    margin-bottom: 15px;
}

.questionTitle {
    font-weight: bold;
    margin-bottom: 5px;
    white-space: normal;
}

.tableTitle {
    font-family: 'Droid Sans';
    margin-bottom: 10px;
}

.functions {
    display: flex;
    flex-direction: column;
}

.function {
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}
.bold {
    font-weight: bold;
}

.editRow {
    display: flex;
    justify-content: flex-end;
}
