.warningTextWrapper {
    font-size: 16px;
    font-style: italic;
    color: gray;
}

.deletedItemsFilter {
    font-family: 'Droid Sans';
    margin-bottom: 10px;
    display: flex;
}