.titleWrapper {
    display: flex;
    justify-content: flex-start;
}

.togglerWrapper {
    display: flex;
}

.toggler{
    margin-left: 10px;
    margin-right: 5px;
    margin-top: 2px;
}

.text
{
    margin-bottom: 5px;
    font-family: 'Droid Sans';
    text-transform: uppercase;
}

.textSkipped
{
    margin-bottom: 5px;
    font-family: 'Droid Sans';
    text-transform: none;
}