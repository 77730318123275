.icon {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.infoWrapper {
    font-size: 14px;
}
