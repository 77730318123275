.tableHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recommendation{
  display: flex;
  align-items: center;
}
.tableTitle {
  font-family: 'Droid Sans';
  margin-bottom: 10px;
}

.recommendationButton {
  margin-bottom: 10px;
  margin-left: 15px;
}

.confirmButton {
  display: flex;
  justify-content: flex-end;
  z-index: 2;
  margin-right: 10px;
}

.confirmButtonWrapper {
  position: relative;
}
