.functionHeader {
    margin: 0px 0 10px;
}

.header {
    margin: 20px 0 10px;
}

.dragging {
    border: 3px dashed black;
}