.actions {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
.action {
    margin-right: 5px;
}
