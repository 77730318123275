.actions {
    text-align: right;
}

.checkbox {
    margin-left: 22px;
}

.textValue {
    text-align: left;
}

.groupCell {
    display: flex;
    align-items: center;

    .icon {
        margin-left: 10px;
        cursor: pointer;
        color: red;
    }
}