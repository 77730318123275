@import '../../scss/vars';

.userbar {
    display: flex;
    align-items: center;
    color: #555;    
}

.name {
    font-weight: 400;
    margin-bottom: 2px;
    font-family: 'Droid Sans';
    margin-right: 15px;
}

.button {
    background-color: #ccc;
    border-radius: 2px;
    padding: 5px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.impersonation {
    margin-right: 5px;
    margin-left: -10px;

    div {    
        text-align: center;
        display: inline-block;
        color: #dc3545;
        width: 30px;
        cursor: pointer;
        margin-bottom: 2px;   
    }
}

.impersonate {
    background-color: transparent;
}

.colorBlind {
    background-color: transparent;
    position: relative;
}

.colorBlindIndicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $contrastGreen;
}