.buttonsWrapper {
    display: flex;
    justify-content: flex-end;
}

.actionButton {
    border: 1px solid #b6b6b65c;
    background-color: #ffffff;
    padding: 0.2em;
    border-radius: 2px;

    &:nth-child(2) {
        margin-left: 5px;
    }
}

.actionButtonNoBorder {
    border-style: none;
}
