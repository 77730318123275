.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.links {
    display: flex;
    align-items: center;
}

.button {
    display: flex;
    align-items: center;
}

.item {
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
    &:not(:last-child) {
        &:after {
            content: '|';
            padding: 0 5px;
            display: inline-block;
            text-decoration: none !important;
        }
    }
    &:hover {
        color: #0076b7;
        // text-decoration: none !important;
    }
}

.activeLink {
    text-decoration: underline;
}

/*#region Dropdown*/

.dropdown {
    position: relative;
    display: inline-block;
    color: #0076b7;
}

.dropdown .item {
    &:after {
        content: '';
    }
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdownContent a {
    color: black;
    padding: 12px 16px;
    display: block;
}

.dropdownContent a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdownContent {
    display: block;
}

/*#endregion */