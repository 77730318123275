.picker {
    padding: 0;
    height: 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &::-webkit-color-swatch-wrapper {
        padding: 0;    
    }
    
    &::-webkit-color-swatch {
        border: none;
        border-radius: 4px;
    }
}