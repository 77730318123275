.requestsClear {
    cursor:pointer;
    margin-left:10px;
}

.requestsFilter {
    float: right;
  
    input {
      margin: 0;
      padding: 0 .25rem;
      width: 200px;
      height: 1.75rem;
    }
  }

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
}  