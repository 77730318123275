.container {
    display: block;
    position: relative;
}

.text {
    z-index: 2;
    background-color: #fff;
}

.iconWrapper {
    position: absolute;
    cursor: pointer;
    width: 25px;
    height: 25px;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 99;
}

.alwaysVisible {
    visibility: visible;
}

.icon {
    position: absolute;
    cursor: pointer;
    top: 0;
    transition: left linear 0.2s;
}
