.container {
    position: relative;
    width: 100px;
    height: 20px;
}

.body {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

.text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(calc(-50% + 4px), -50%);
    width: 100%;
    padding: 5px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pre {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    top: 5px;
    left: -5px;
    background-color: #fff;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(45deg) matrix(1, 0.3, 0.3, 1, 0, 0);
}

.post {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    top: 5px;
    right: -5px;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(45deg) matrix(1, 0.3, 0.3, 1, 0, 0);
}
