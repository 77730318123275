.container {
  display: block;
  text-align: center;
}

.iconWrapper {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.icon {
  cursor: pointer;
  color: #959595;
}