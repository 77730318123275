.comment {
    white-space: pre-line;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

.red {
    color: red;
}

.commentText {
    margin-bottom: 5px;
    margin-top: 5px;
}
.button {
    padding: 1px 5px;
    padding-bottom: 2px;
    margin: 5px;
}