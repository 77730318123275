.container {
    width: 100%;
    color: #555;
}

.description {
    margin-bottom: 20px;
}

.checkbox {
    margin-left: 15px;
}

.open {
    position: relative;
    padding-right: 20px;
    cursor: pointer;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 7px solid #555;
        transition: transform linear 0.1s;
    }
}

.close {
    composes: open;
    &:after {
        transform: translateY(-50%) rotate(180deg);
    }
}
.statusImage{
    width: 40px;
}
