.container {
    width: 100%;
}

.actions {
    display: flex;
    justify-content: flex-end;
}

.button {
    white-space: nowrap;
    &:not(:last-child) {
        margin-right: 5px;
    }
}
