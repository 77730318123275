.confirmationInputWrapper {
    position: relative;
}

.confirmationInput {
    width: 70%;
    padding: 0.9em 1.3em;
    border: unset;
    border-radius: 3px;
    background-color: rgb(235, 236, 240);
    line-height: 1.3;
    &::placeholder {
        line-height: 1.3;
    }
}

.focusedInput {
    border: 2px solid #4180d1;
    background-color: #fff;
}

.invalidInput {
    border: 2px solid #dc3545;
    background-color: #fff;
}

.buttonsWrapper {
    position: absolute;
    right: 0;
    margin-top: 5px;
    z-index: 1;
}

.actionButton {
    border: 1px solid #b6b6b65c;
    background-color: #ffffff;
    padding: 0.4em;
    border-radius: 2px;

    &:nth-child(2) {
        margin-left: 5px;
    }
}
