.dx-widget,
.dx-widget input,
.dx-widget textarea {
    font-family: 'Droid Sans' !important;
    font-size: 14px !important;
}

.dx-box-item-content {
    font-size: 14px !important;
}

.dx-texteditor-input {
    height: auto !important;
}

.dx-row td:hover .copyIcon {
    visibility: visible;
}
