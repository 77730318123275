.container {
    overflow: hidden;
    border: 1px solid #ddd;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}

.header {
    display: flex;
    align-items: center;
    padding: 7px;
}

.body {
    padding: 8px;
    color: #000;
    overflow-y: auto;
    overflow-x: hidden;
}

.select {
    margin-left: 20px;
    width: 280px;
    color: #212529;
}
