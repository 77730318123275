.line {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;

    .checkbox{
        display: flex;
        justify-content: flex-end;
        flex-direction: row-reverse;
        flex: 1 0 auto;
        margin-bottom: 10px;
        & :last-child{
            margin-right: 10px;
        }
        & :first-child *{
            margin-bottom: 0;
        }
        & *{
            margin-bottom: 0;
        }
    }
}

.item {
    flex: 1 0 auto;
    max-width: calc(33.3% - 10px);
    width: 20%;

    margin-bottom: 5px;
    margin-right: 10px;
    &:nth-child(3) {
        margin-right: 0;
    }
}

.title {
    font-family: 'Droid Sans';
    text-transform: uppercase;
    margin-bottom: 5px;
}

.data {
    padding: 2px 5px;
    background-color: #d1d1d1;
    min-height: 25px;
    border-radius: 3px;
}

.gateDescriptionTooltipText {
    font-size: 14px;
}

.buttons {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.button {
    &:not(:last-child) {
        margin-right: 10px;
    }
}
.inputWrap{
    position: relative;
    min-height: 30px;
}
.input {
    height: 25px !important;
    max-width: 100% !important;
    margin-left: 0;
}
