.deletedItemFilterWrapper {
    display: flex;
}

.togglerWrapper {
    margin-left: 10px;
}

.titleWrapper {
    margin-left: 10px;
}