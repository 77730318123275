.container {
    display: flex;
}

.button {
    margin-left: auto;
}

.line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.halfField {
    width: calc(50% - 5px);

    &:first-child {
        margin-right: 5px;
    }
}
.checkbox{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    & :last-child{
        margin-right: 10px;
    }
    & :first-child *{
        margin-bottom: 0;
    }
}

.thirdField {
    width: calc(35% - 5px);

    &:first-child {
        margin-right: 5px;
    }

    &:last-child {
        margin-left: 5px;
    }
}

.field {
    width: 100%;

    &:not(:last-child) {
        margin-right: 5px;
    }
}

.datePicker {
    max-width: 100% !important;
}

.rowLabel {
    margin-right: 10px;

    em {
        color: red;
    }
}
