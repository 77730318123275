.commentColumn {
    position: relative;
}

.commentTextArea {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0;
    top: 0;
    resize: unset;
}

.colWithHistory {
    position: relative;
}