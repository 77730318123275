.image {
    width: 13px;
    height: 13px;
}

.actions {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}

.button {
    &:not(:last-child) {
        margin-right: 10px;
    }
}
