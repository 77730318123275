.container {
    background-color: #e5f0f7;
    padding: 10px;
    width: 100%;
    color: #999;
    border-bottom: 1px solid #999;
}

.line {
    width: 100%;
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.userbar {
    margin-left: auto;
}
