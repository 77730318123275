.widgets {
    display: flex;
    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }
}

.column {
    width: calc( 50% - 10px );
    &:not(:last-child) {
        margin-right: 10px;
    }
    &:last-child {
        margin-left: 10px;
    }
    @media (max-width: 1200px) {
        width: 100%;
        &:not(:last-child) {
            margin-right: 0;
        }
        &:last-child {
            margin-left: 0;
        }
    }
}

.widget {
    margin-bottom: 20px;

    @media (max-width: 1200px) {
        margin-bottom: 20px;
    }
}
