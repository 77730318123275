.container {
    margin-left: auto;
    align-self: center;
    margin-right: 5px;
}

.modal {
    max-width: 70%;
    min-width: 70%;
}

.cursor {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
