.wrapper {
    display: flex;
}

.select {
    flex: 1 1 50%;
}

.dateSelect {
    flex: 1 1 50%;
}

.column {
    flex-direction: column;
}