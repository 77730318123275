.expandable {
    & td > div {
        max-height: 70px;
    }
}

.arrow {
    cursor: pointer;
}

.grid {
    td {
        word-break: break-word;
    }
}
